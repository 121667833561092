import React from "react";
import {
  TableCell,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { Row, Scenario } from "./data/Scenario";
import { TitleDefinitionConstants, UserRoles } from "constants/constant";
import { baseYears } from "../ProjectRegister/data/ProjectRegisterData";
import { useAppState } from "context/appState.context";

interface ScenarioTableRowProps {
  index: number;
  row: Row;
  scenarioKeys: string[];
  startYear: { [key: string]: string };
  execution: { [key: string]: string };
  peace: { [key: string]: string };
  invalidInput: boolean;
  handleExecutionPercentageChange: (
    scenarioKey: string,
    value: string,
    rowIndex: number,
    reductionMeasureId: number,
    scenarioSection: string
  ) => void;
  handlePeaceChange: (
    scenarioKey: string,
    value: string,
    rowIndex: number,
    reductionMeasureId: number,
    scenarioSection: string
  ) => void;
  handleFilterChange: (
    scenarioKey: string,
    value: string | null | number,
    rowIndex: number,
    reductionMeasureId: number,
    scenarioSection: string
  ) => void;
}

/**
 * ScenarioTableRow component renders a table row with form controls for different scenarios.
 *
 * @component
 * @param {ScenarioTableRowProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const ScenarioTableRow: React.FC<ScenarioTableRowProps> = ({
  index,
  row,
  scenarioKeys,
  startYear,
  execution,
  peace,
  invalidInput,
  handleExecutionPercentageChange,
  handlePeaceChange,
  handleFilterChange,
}) => {
  const { userRole } = useAppState();
  return (
    <TableRow key={index}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{row.item}</TableCell>
      {scenarioKeys.map((scenarioKey) => (
        <React.Fragment key={scenarioKey}>
          <TableCell>
            <FormControl margin="normal">
              <Select
                id={`${scenarioKey}-${index}-startYear`}
                name={`${scenarioKey}-${index}-startYear`}
                disabled={scenarioKey == "Scenario A" || userRole === UserRoles.Viewers}
                value={
                  startYear[`${scenarioKey}-${index}`] !== undefined
                    ? startYear[`${scenarioKey}-${index}`]
                    : (row[scenarioKey] as Scenario)?.startYear
                }
                onChange={(e) =>
                  handleFilterChange(
                    scenarioKey,
                    e.target.value,
                    index,
                    (row[scenarioKey] as Scenario)?.reductionMeasureId,
                    (row[scenarioKey] as Scenario)?.scenarioSection
                  )
                }
                sx={{ width: "90px" }}
              >
                <MenuItem value="0">Select Start Year</MenuItem>
                {baseYears.map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </TableCell>
          <TableCell>
            <Tooltip
              title={
                parseInt(execution[`${scenarioKey}-${index}`]) > 100
                  ? TitleDefinitionConstants.ReductionMeasure.Execution
                  : ""
              }
              arrow
              open={parseInt(execution[`${scenarioKey}-${index}`]) > 100}
              placement="top"
            >
              <TextField
                id={`${scenarioKey}-${index}-execution`}
                name={`${scenarioKey}-${index}-execution`}
                inputProps={{
                  "data-testid": `executiontestid-${scenarioKey}-${index}`,
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                required
                disabled={scenarioKey == "Scenario A" || userRole === UserRoles.Viewers}
                value={
                  execution[`${scenarioKey}-${index}`] !== undefined
                    ? execution[`${scenarioKey}-${index}`]
                    : (row[scenarioKey] as Scenario)?.execution
                }
                onChange={(e) =>
                  handleExecutionPercentageChange(
                    scenarioKey,
                    e.target.value,
                    index,
                    (row[scenarioKey] as Scenario)?.reductionMeasureId,
                    (row[scenarioKey] as Scenario)?.scenarioSection
                  )
                }
                fullWidth
                sx={{
                  width: "80px",
                  border:
                    invalidInput &&
                    parseInt(execution[`${scenarioKey}-${index}`]) > 100
                      ? "2px solid red"
                      : "",
                }}
              />
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip
              title={
                parseInt(peace[`${scenarioKey}-${index}`]) > 100
                  ? TitleDefinitionConstants.ReductionMeasure.Execution
                  : ""
              }
              arrow
              open={parseInt(peace[`${scenarioKey}-${index}`]) > 100}
              placement="top"
            >
              <TextField
                id={`${scenarioKey}-${index}-pace`}
                name={`${scenarioKey}-${index}-pace`}
                required
                disabled={scenarioKey == "Scenario A" || userRole === UserRoles.Viewers}
                value={
                  peace[`${scenarioKey}-${index}`] !== undefined
                    ? peace[`${scenarioKey}-${index}`]
                    : (row[scenarioKey] as Scenario)?.pace
                }
                inputProps={{
                  "data-testid": `pace-${scenarioKey}-${index}`,
                  inputMode: "numeric",
                  maxLength: 3,
                }}
                onChange={(e) =>
                  handlePeaceChange(
                    scenarioKey,
                    e.target.value,
                    index,
                    (row[scenarioKey] as Scenario)?.reductionMeasureId,
                    (row[scenarioKey] as Scenario)?.scenarioSection
                  )
                }
                fullWidth
                sx={{
                  width: "70px",
                  border:
                    invalidInput &&
                    parseInt(peace[`${scenarioKey}-${index}`]) > 100
                      ? "2px solid red"
                      : "",
                }}
              />
            </Tooltip>
          </TableCell>
        </React.Fragment>
      ))}
    </TableRow>
  );
};

export default ScenarioTableRow;
