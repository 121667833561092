import React from "react";
import { List, ListItemButton, Typography, Box, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ToolTipMessage from "components/Tooltip";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

/**
 * ChildItems component represents a list of child items under a parent menu item.
 * It handles navigation and highlighting of the child items based on the active route.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Array} props.children - Array of child items to be rendered.
 * @param {boolean} props.open - Boolean indicating whether the navigation menu is open or not.
 * @param {boolean} props.dashboardsTabOpen - Boolean indicating whether the dashboards tab is open or not.
 * @param {string} props.primary - Primary color for highlighting.
 * @param {string} props.secondary - Secondary color for default state.
 * @returns {JSX.Element} The rendered component.
 */
const ChildItems = ({ children, open, dashboardsTabOpen, primary, secondary }: any) => {
  const { OrganizationId } = useParams();
  const Navigate = useNavigate();

  /**
   * Handles navigation on child item click and updates the localStorage with the current child item.
   *
   * @param {string} link - The link to navigate to.
   * @param {string} itemLabel - The label of the current child item.
   * @param {string} mainMenu - The main menu label for highlighting purposes.
   */
  const onClickNavigation = (link: string, item: string, mainMenu: string) => {
    localStorage.setItem("Highlights", item);
    localStorage.setItem("HighlightsMenu", mainMenu);
    Navigate(link);
  };

  return (
    <Box sx={{ borderLeft: 2, ml: 2, borderColor: secondary }}>
      <List data-testid="subTabs" hidden={!dashboardsTabOpen}>
        <Stack direction={"column"}>
          {children.map((c: any) => {
            const isChildHighlighted = c.label === localStorage.getItem("HighlightsMenu");
            return (
              <React.Fragment key={c.id}>
                <ListItemButton
                  sx={{ fontSize: open ? "medium" : "small" }}
                  onClick={() => {
                    const highlightsMenu = localStorage.getItem("HighlightsMenu");
                  
                    // Check if "Portfolio view" is in the HighlightsMenu
                    if (highlightsMenu === "Portfolio view") {
                      // Change the path for "Portfolio view"
                      
                      onClickNavigation(`/Organization/Portfolioview/${c.link}`, c.label, c.label);
                      localStorage.setItem("HighlightsMenu", "Portfolio view");
                      localStorage.setItem("Highlights", c);
                    } else {
                      // Default path for other menu items
                      onClickNavigation(`/Organization/${OrganizationId}/${c.link}`, c.label, c.label);
                    }
                  }}
                  className={isChildHighlighted ? "active" : "in-active"}
                >
                  {open ? (
                    <Typography
                      sx={{
                        whiteSpace: "pre-line",
                        color: `${isChildHighlighted ? primary : secondary}`,
                      }}
                    >
                      {c.label}
                    </Typography>
                  ) : (
                    <ToolTipMessage
                      title={c.label}
                      placement={"auto"}
                      component={
                        <ArrowRightIcon
                          fontSize="small"
                          sx={{ color: `${isChildHighlighted ? primary : secondary}` }}
                        />
                      }
                    />
                  )}
                </ListItemButton>
              </React.Fragment>
            );
          })}
        </Stack>
      </List>
    </Box>
  );
};

export default ChildItems;
