import { Button, Card, Grid, Typography } from "@mui/material";
import {
  DataGrid,
  GridColumnMenu,
  GridColumnMenuProps,
} from "@mui/x-data-grid";
import FormDialog from "components/Dialog/modal-popup";
import { ReductionMeasureListModel } from "object-models/ReductionMeasures";
import React, { useEffect } from "react";
import CreateUpdateReductionMeasures from "../CreateUpdateReductionMeasures";
import { AlertsProps } from "components/Alerts/data/Alerts.type";
import { useLocation } from "react-router-dom";
import { TitleDefinitionConstants, UserRoles } from "constants/constant";
import { useAppState } from "context/appState.context";

interface ListReductionMeasuresDesignProps {
  reductionMeasureList: Array<ReductionMeasureListModel>;
  columns: any;
  openEditModal: boolean;
  selectedReductionMeasureId: number;
  customColumnMenu?: React.JSX.Element;
  onAddMeasureClick: () => void;
  handleCloseSnackBar: (state: AlertsProps) => void;
  hideDialogHandler: () => void;
  isRefreshEditClose: () => void;
}

/**
 * Custom column menu component for the DataGrid.
 *
 * @param {GridColumnMenuProps} props - The props for the column menu component.
 * @returns {JSX.Element} The rendered column menu.
 */
const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
      }}
    />
  );
};

/**
 * ListReductionMeasuresDesign component displays a list of reduction measures in a DataGrid and handles interactions for adding and editing measures.
 *
 * @param {ListReductionMeasuresDesignProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */

export default function ListReductionMeasuresDesign(
  props: Readonly<ListReductionMeasuresDesignProps>
) {
  const location = useLocation();
  const { userRole } = useAppState();
  
  useEffect(() => {
    if (location.state?.triggerAddMeasure) {
      props.onAddMeasureClick();
      localStorage.setItem("HighlightsMenu", "Reduction Measures");
    }
  }, [location.state, props]);

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={5} md={7}>
          <Typography
            fontSize="24px"
            fontFamily={"Inter"}
            fontWeight={700}
            data-testid="reductionMeasures"
          >
            Measure Details
          </Typography>
        </Grid>
        <Grid item xs={4} md={5} textAlign={"right"}>
          {userRole !== UserRoles.Viewers && (
            <Button
              variant="contained"
              data-testid="AddNew-ReductionMeasure-Button"
              onClick={() => {
                props.onAddMeasureClick();
              }}
            >
              Add Measure
            </Button>
          )}
        </Grid>
      </Grid>
      <Card sx={{ mt: 2, mb: 0 }}>
        {props.reductionMeasureList.length > 0 ? (
          <Grid container sx={{ mt: 0, mb: 0 }}>
            <Grid item xs={12}>
              <DataGrid
                rows={props.reductionMeasureList}
                columns={props.columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                disableRowSelectionOnClick
                getRowId={(row) => row?.reductionMeasureId}
                slots={{ columnMenu: CustomColumnMenu }}
                columnBuffer={10}
                autoHeight
                localeText={{
                  noRowsLabel:
                    TitleDefinitionConstants.redutionMeasurenoRowsLabel,
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={11} style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              id="test-typography-01"
              component="div"
              color="gray"
              style={{ fontWeight: "bold" }}
              sx={{ mt: 15, mb: 20, ml: 20 }}
            >
              {TitleDefinitionConstants.redutionMeasurenoRowsLabel}
            </Typography>
          </Grid>
        )}
      </Card>
      <FormDialog
        data-testid="alert-dialog-create"
        dialogOpen={props.openEditModal}
        hideDialogHandler={props.hideDialogHandler}
        dialogTitle="Edit Measure Information"
        isFullScreenAllowed={true}
        childComponent={
          <CreateUpdateReductionMeasures
            isEdit={true}
            selectedReductionMeasureId={props.selectedReductionMeasureId}
            closeDialog={props.hideDialogHandler}
            handleCloseSnackBar={props.handleCloseSnackBar}
            isRefreshEditClose={props.isRefreshEditClose}
          ></CreateUpdateReductionMeasures>
        }
      ></FormDialog>
    </React.Fragment>
  );
}
