import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import numericValidator from "services/numericValidator";
import {
  _addScenario,
  _getScenarioByOrganizationsID,
} from "middlewares/DashboardApi/scenarioView";
import { ScenarioModel } from "./data/ScenarioModel";
import Spinner from "components/Spinner";
import { Row, Scenario } from "./data/Scenario";
import EmptyState from "./EmptyState";
import ScenarioTableRow from "./ScenarioTableRow";
import ScenarioViewHeader from "./ScenarioViewHeader";
import { useAppState } from "context/appState.context";
import { TitleDefinitionConstants, UserRoles } from "constants/constant";


/**
 * ScenarioView component handles the display and management of scenarios
 * related to the Net Zero Carbonization Model for a specific organization.
 *
 * @returns {JSX.Element} The ScenarioView component.
 */
const ScenarioView = () => {
  const { OrganizationId } = useParams();
  const { userRole } = useAppState();

  // State variables for executionPercentage and peace
  const [execution, setExecution] = useState<{ [key: string]: string }>({});
  const [peace, setPeace] = useState<{ [key: string]: string }>({});
  const [rows, setRows] = useState<Row[]>([]);
  const [scenarioKeys, setScenarioKeys] = useState<string[]>([]);
  const [startYear, setStartYear] = useState<{ [key: string]: string }>({});
  const [responseState, setResponseState] = useState<Array<ScenarioModel>>([]);
  const [loading, setLoading] = useState(true);
  const [invalidInput, setInvalidInput] = useState(false);

  const numberPattern = /^(?:100|\d{1,3})?$/;

  const navigate = useNavigate();

  const dynamicUrl = `/Organization/${OrganizationId}/Reduction-Measures`;

  /**
   * Fetches the scenario data for the given organization ID.
   * Groups the results by reduction measure ID and sets the rows and scenario keys.
   */
  const getScenarioByOrganizationsId = async () => {
    try {
      setLoading(true);
      const res = await _getScenarioByOrganizationsID(Number(OrganizationId));
      if (res) {
        setResponseState(res);
        const uniqueSections = [
          ...new Set(res.map((item) => item.scenarioSection)),
        ].filter(
          (section) => section !== null && section !== undefined
        ) as string[];
        setScenarioKeys(uniqueSections);

        let groupedResult = groupBy<ScenarioModel>(res, "reductionMeasureId");

        const updatedRows = Object.keys(groupedResult).map(
          (reductionMeasureId) => {
            const scenarios = groupedResult[reductionMeasureId];
            const scenarioData: { [key: string]: Scenario } = {};
            scenarios.forEach((scenario: any) => {
              const {
                startYear,
                execution,
                pace,
                scenarioSection,
                reductionMeasureId,
              } = scenario;
              scenarioData[scenarioSection] = {
                startYear,
                execution,
                pace,
                scenarioSection,
                reductionMeasureId,
              };
            });

            return {
              item: scenarios[0].reductionMeasureName,
              ...scenarioData,
            };
          }
        );
        setRows(updatedRows);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Groups an array of objects by a specified key.
   *
   * @template T
   * @param {T[]} collection - The array of objects to group.
   * @param {keyof T} key - The key to group by.
   * @returns {Record<string, T[]>} The grouped objects.
   */
  function groupBy<T>(collection: T[], key: keyof T) {
    const groupedResult = collection.reduce((previous, current) => {
      if (!previous[current[key]]) {
        previous[current[key]] = [] as T[];
      }

      previous[current[key]].push(current);
      return previous;
    }, {} as any); // tried to figure this out, help!!!!!
    return groupedResult;
  }

  useEffect(() => {
    getScenarioByOrganizationsId();
  }, []);

  /**
   * Updates the response state with new values for a specific scenario.
   *
   * @param {number} reductionMeasureId - The ID of the reduction measure.
   * @param {string} scenarioSection - The section of the scenario.
   * @param {object} updatedValues - The new values to update.
   */
  const updateResponseState = (
    reductionMeasureId: number,
    scenarioSection: string,
    updatedValues: any
  ) => {
    setResponseState((prevState) =>
      prevState.map((scenario) =>
        scenario.reductionMeasureId === reductionMeasureId &&
        scenario.scenarioSection === scenarioSection
          ? { ...scenario, ...updatedValues }
          : scenario
      )
    );
  };

  /**
   * Handles the change event for execution percentage input fields.
   *
   * @param {string} scenarioKey - The key of the scenario.
   * @param {string} value - The new value of the input field.
   * @param {number} rowIndex - The index of the row being updated.
   * @param {number} reductionMeasureId - The ID of the reduction measure.
   * @param {string} scenarioSection - The section of the scenario.
   */
  const handleExecutionPercentageChange = (
    scenarioKey: string,
    value: string,
    rowIndex: number,
    reductionMeasureId: number,
    scenarioSection: string
  ) => {
    if (value === "") {
      setExecution((prev) => ({
        ...prev,
        [`${scenarioKey}-${rowIndex}`]: "",
      }));
      updateResponseState(reductionMeasureId, scenarioSection, {
        execution: "",
      });
      setInvalidInput(false);
      return;
    }
    const numericValue = numericValidator.allowNumericValue(value);
    if (numberPattern.test(String(numericValue)) || value === "") {
      setExecution((prev) => ({
        ...prev,
        [`${scenarioKey}-${rowIndex}`]: String(numericValue),
      }));
      setInvalidInput(parseInt(numericValue.toString()) > 100);
      updateResponseState(reductionMeasureId, scenarioSection, {
        execution: String(numericValue),
      });
    }
  };

  /**
   * Handles the change event for pace input fields.
   *
   * @param {string} scenarioKey - The key of the scenario.
   * @param {string} value - The new value of the input field.
   * @param {number} rowIndex - The index of the row being updated.
   * @param {number} reductionMeasureId - The ID of the reduction measure.
   * @param {string} scenarioSection - The section of the scenario.
   */
  const handlePeaceChange = (
    scenarioKey: string,
    value: string,
    rowIndex: number,
    reductionMeasureId: number,
    scenarioSection: string
  ) => {
    if (value === "") {
      setPeace((prev) => ({
        ...prev,
        [`${scenarioKey}-${rowIndex}`]: "",
      }));
      updateResponseState(reductionMeasureId, scenarioSection, { pace: "" });
      setInvalidInput(false);
      return;
    }

    const numericValue = numericValidator.allowNumericValue(value);
    if (numberPattern.test(String(numericValue)) || value === "") {
      setPeace((prev) => ({
        ...prev,
        [`${scenarioKey}-${rowIndex}`]: String(numericValue),
      }));
      setInvalidInput(parseInt(numericValue.toString()) > 100);
      updateResponseState(reductionMeasureId, scenarioSection, {
        pace: String(numericValue),
      });
    }
  };

  /**
   * Handles the change event for filter input fields.
   *
   * @param {string} scenarioKey - The key of the scenario.
   * @param {string | null | number} value - The new value of the input field.
   * @param {number} rowIndex - The index of the row being updated.
   * @param {number} reductionMeasureId - The ID of the reduction measure.
   * @param {string} scenarioSection - The section of the scenario.
   */
  const handleFilterChange = (
    scenarioKey: string,
    value: string | null | number,
    rowIndex: number,
    reductionMeasureId: number,
    scenarioSection: string
  ) => {
    setStartYear((prev) => ({
      ...prev,
      [`${scenarioKey}-${rowIndex}`]: String(value),
    }));
    updateResponseState(reductionMeasureId, scenarioSection, {
      startYear: value,
    });
  };

  /**
   * Saves the current scenario state to the server.
   */
  const handleSave = async () => {
    try {
      setLoading(true);
      const res = await _addScenario(responseState);
      if (res) {
        setLoading(false);
        getScenarioByOrganizationsId();
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Resets the state variables to their initial values.
   */
  const resetStateVariables = () => {
    setExecution({});
    setPeace({});
    setRows([]);
    setScenarioKeys([]);
    setStartYear({});
    setResponseState([]);
    setLoading(true);
    setInvalidInput(false);
  };

  /**
   * Cancels any changes and reloads the scenario data.
   */
  const handleCancel = () => {
    resetStateVariables();
    getScenarioByOrganizationsId();
  };

  /**
   * Redirects the user to the reduction measures page.
   */
  const handleRedirect = () => {
    navigate(dynamicUrl, { state: { triggerAddMeasure: true } });
  };

  /**
   * Renders the scenario table or an empty state if there are no rows.
   *
   * @returns {JSX.Element} The scenario table or empty state.
   */
  const renderScenarios = () => {
    if (rows.length > 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ ml: 2.5, mb: 5 }}>
            <Paper variant="outlined">
              <Grid item sx={{ m: 1 }}>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#05004E",
                    width: "100%",
                    marginLeft: "8px",
                  }}
                >
                  Metrics
                </div>
              </Grid>
              <Grid item>
                <Paper variant="outlined">
                  <TableContainer style={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            Scenarios
                          </TableCell>
                          {scenarioKeys.map((scenarioKey) => (
                            <React.Fragment key={scenarioKey}>
                              <TableCell
                                colSpan={3}
                                sx={{ fontWeight: "bold", textAlign: "center" }}
                              >
                                {scenarioKey}
                              </TableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>#</TableCell>
                          <TableCell
                            style={{
                              minWidth: "140px",
                            }}
                            sx={{ fontWeight: "bold" }}
                          >
                            Measure Name
                          </TableCell>
                          {scenarioKeys.map((scenarioKey) => (
                            <React.Fragment key={scenarioKey}>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Start Year
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Execution %
                              </TableCell>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Pace %
                              </TableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <ScenarioTableRow
                            key={index + 1}
                            index={index}
                            row={row}
                            scenarioKeys={scenarioKeys}
                            startYear={startYear}
                            execution={execution}
                            peace={peace}
                            invalidInput={invalidInput}
                            handleExecutionPercentageChange={
                              handleExecutionPercentageChange
                            }
                            handlePeaceChange={handlePeaceChange}
                            handleFilterChange={handleFilterChange}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      );
    } else {
      return <EmptyState handleRedirect={handleRedirect} />;
    }
  };

  return (
    <Grid container spacing={2}>
      <ScenarioViewHeader />
      {rows.length > 0 ? (
        <>
          <Grid container spacing={2}>
            {userRole !== UserRoles.Viewers && (
              <Grid item xs={9.5} sx={{ mr: 5, mb: 5 }}>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button
                      type="submit"
                      data-testid="btnCancel"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item sx={{ ml: 2 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      data-testid="btnSave"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <br />
          <Grid item xs={8.3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "primary.main",
                }}
              >
                Note:
              </Typography>
              <Typography
                data-testid="blurb-message-info"
                sx={{ p: 1, whiteSpace: "pre-line", ml: 1 }}
              >
                {TitleDefinitionConstants.ScenarioViewInfo}
              </Typography>
            </Box>
          </Grid>
        </>
      ) : (
        ""
      )}

      {loading ? (
        <Spinner size={80} data-testid="spinner" />
      ) : (
        renderScenarios()
      )}
    </Grid>
  );
};

export default ScenarioView;
