import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";

import { OrganizationModel } from "object-models/organization-model";
import EmissionByScopeCategoryBarChart from "pages/Dashboards/BaselineTargetEmission/EmissionByScopeCategoryBar.Chart";
import { DataItem } from "../Data/PortfolioViewData";

interface EmissionByScopeCategoryBarProps {
  data: OrganizationModel;
}

/**
 * A React functional component that renders a bar chart showing emissions categorized by various activities.
 * The chart dynamically updates based on the emission data passed.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {OrganizationModel} props.data - The organization emission data containing values for different categories of emissions.
 */
const EmissionByScopeCategoryBar: React.FC<EmissionByScopeCategoryBarProps> = ({
  data,
}) => {
  const defaultBarChatWidth = 600;
  const CATEGORY_HEIGHT = 38;
  const defaultHeight = 600;

  const [barChartData, setBarChartData] = useState([] as Array<DataItem>);

  /**
   * Helper function to create a `DataItem` object.
   * If the `value` is undefined, it defaults to 0.
   *
   * @function
   * @param {string} name - The name of the emission category.
   * @param {number | undefined} value - The emission value of the category.
   * @returns {DataItem} - A `DataItem` object containing the name and value.
   */
  const createDataItem = (
    name: string,
    value: number | undefined
  ): DataItem => ({
    name,
    value: value ?? 0,
  });

  /**
   * Generates and returns the data for the bar chart based on the emission data.
   *
   * @function
   * @returns {Array<DataItem>} - An array of `DataItem` objects for each emission category.
   */
  const barData = (): Array<DataItem> => {
    const dataItems = [
      createDataItem(
        "Purchased Goods And Services",
        data.purchasedGoodsAndServices
      ),
      createDataItem("Capital Goods", data.capitalGoods),
      createDataItem(
        "Fuel and Energy Related Activities",
        data.fuelandEnergyRelatedActivities
      ),
      createDataItem(
        "Upstream Transportation And Distribution",
        data.upstreamTransportationAndDistribution
      ),
      createDataItem(
        "Waste Generated In Operations",
        data.wasteGeneratedinOperations
      ),
      createDataItem("Business Travel", data.businessTravel),
      createDataItem("Employee Commuting", data.employeeCommuting),
      createDataItem("Upstream Leased Assets", data.upstreamLeasedAssets),
      createDataItem(
        "Downstream Transportation And Distribution",
        data.downstreamTransportationAndDistribution
      ),
      createDataItem(
        "Processing of Sold Products",
        data.processingofSoldProducts
      ),
      createDataItem("Use of Sold Products", data.useofSoldProducts),
      createDataItem(
        "End of Life Treatment of Sold Products",
        data.endofLifeTreatmentofSoldProducts
      ),
      createDataItem("Downstream Leased Assets", data.downstreamLeasedAssets),
      createDataItem("Franchises", data.franchises),
      createDataItem("Investments", data.investments),
      createDataItem("Other Upstream", data.otherUpstream),
      createDataItem("Other Downstream", data.otherDownstream),
    ];

    return dataItems;
  };

  /**
   * Reshapes the bar chart data by calling `barData` and updating the state.
   *
   * @function
   */
  function reshapeData() {
    setBarChartData(barData);
  }

  // Update bar chart data when `data` changes
  useEffect(() => {
    reshapeData();
  }, [data]);

  return (
    <Grid item xs={6.2}>
      <Paper variant="outlined" sx={{ p: 1 }}>
        {" "}
        <EmissionByScopeCategoryBarChart
          width={defaultBarChatWidth}
          categoryHeight={CATEGORY_HEIGHT}
          height={defaultHeight}
          data={barChartData}
        />
      </Paper>
    </Grid>
  );
};
export default EmissionByScopeCategoryBar;
