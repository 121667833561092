import React from "react";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import group1 from "../../../assets/Images/dashboard/group1.png";
import smoke from "../../../assets/Icons/smoke.png";
import waste from "../../../assets/Icons/waste.png";
import pollution from "../../../assets/Icons/pollution.png";
import { scopesCoveredOptions } from "../Data/PortfolioViewData";

interface TotalEmissionsByScopeProps {
  emissionValues: { [key: string]: number };
  selectedScope: number[];
}

/**
 * A React functional component that displays total emissions by scope.
 * It shows emission values for Scope 1, Scope 2, and Scope 3, and icons for each scope.
 *
 * @component
 * @param {Object} props - Props passed to the component.
 * @param {Object.<string, number>} props.emissionValues - Emission values for each scope, mapped by scope key (e.g., 'Scope 1', 'Scope 2').
 * @param {number[]} props.selectedScope - An array of selected scope IDs to display.
 */
const TotalEmissionsByScope: React.FC<TotalEmissionsByScopeProps> = ({
  emissionValues,
  selectedScope,
}) => {
  /**
   * Renders the emission value for a given scope.
   *
   * @function
   * @param {string} scopeKey - The key of the scope (e.g., 'Scope 1').
   * @returns {string} - The formatted emission value as a string, or "N/A" if not available.
   */
  const renderEmissionValue = (scopeKey: string) => {
    return emissionValues[scopeKey]?.toLocaleString() || "N/A";
  };

  /**
   * Retrieves the corresponding icon for the given scope.
   *
   * @function
   * @param {string} scopeValue - The value of the scope (e.g., 'Scope 1', 'Scope 2').
   * @returns {string} - The path to the corresponding icon image.
   */
  function getScopeIcon(scopeValue: string) {
    switch (scopeValue) {
      case scopesCoveredOptions[0].value:
        return smoke;
      case scopesCoveredOptions[1].value:
        return pollution;
      case scopesCoveredOptions[2].value:
        return waste;
      default:
        return pollution;
    }
  }

  /**
   * Generates and returns JSX for the selected emission scopes.
   * If no scope is selected, a message is displayed asking the user to select a scope.
   *
   * @function
   * @returns {JSX.Element[]} - A list of Grid items representing each selected scope with emission values.
   */
  function emissionByScope() {
    if (selectedScope.length > 0) {
      const selectedScopeList = scopesCoveredOptions.filter((s) =>
        selectedScope.includes(s.id)
      );
      return selectedScopeList.map((s) => {
        return (
          <Grid
            key={s.id}
            item
            xs={4}
            sx={{
              boxShadow: 1,
              p: 1,
              m: 1,
              borderRadius: 5,
              backgroundImage: `url(${group1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item>
              <Stack direction={"column"}>
                <Typography
                  sx={{ fontSize: "24px", flex: "1" }}
                  data-testid={s.value}
                >
                  <b>{renderEmissionValue(s.value)}</b>
                </Typography>
                <div>{s.label}</div>
              </Stack>
            </Grid>
            <Grid item alignSelf={"end"}>
              <img
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={getScopeIcon(s.value)}
                alt={s.value + "Icon"}
              />
            </Grid>
          </Grid>
        );
      });
    } else {
      return (
        <Box sx={{ m: 1, mr: 2, mb: 2 }}>
          <Typography variant="h6" color={"primary"}>
            Select Scope in the filter
          </Typography>
        </Box>
      );
    }
  }

  return (
    <Paper variant="outlined">
      <Grid item sx={{ m: 1 }}>
        <div
          style={{
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "600",
            color: "#05004E",
            width: "100%",
          }}
        >
          Total Emissions By Scope (MT CO₂e)
        </div>
      </Grid>
      <Stack direction={"row"} justifyItems={"center"}>
        {emissionByScope()}
      </Stack>
    </Paper>
  );
};

export default TotalEmissionsByScope;
